.App {
  text-align: center;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.d-table{
  display: table;
  width: 100%;
  border-collapse: collapse;
}
.d-tr{
  display: table-row;
}
.d-tr-main{
  display: table-row;
  background-color: rgba(67, 195, 195, 0.712) 
}
.d-td{
  display: table-cell;
  text-align: center;
  border: none;
  border: 1px solid #ccc;
  vertical-align: middle;
}

.d-td:not(.no-p){
  padding: 4px;
}

.App-paper{
  box-shadow:  rgba(0, 0, 0, 0.5) 0px 0px 5px;
  border-radius: 0 0 5px 5px; 
  padding: 8px;
  /* height: 92vh */
}

.App-divider{
  height: 1px;
  margin: 0;
  border: none;
  flex-shrink: 0;
  background-color: rgba(0, 0, 0, 0.12);
}

.App-padding{
  padding: 8px
}

.MuiTypography-body1{
  color: #333
}